import Editor from './components/Editor';

import './App.css';

function App() {
  return (
    <div className="tldraw__editor">
      <Editor />
    </div>
  );
}

export default App;
